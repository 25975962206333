export const environment = {
    production: true,
    region: 'ap-southeast-2',
    endpoint: 'https://nextview-api.sparkbusinesstechnology.com.au',
    logLevel: 'debug',
    buildId: process.env.NG_APP_BUILD_ID || 'local',
    defaultCollectionLimit:10,
    enableServiceWorker: true,
    enableSentry: true
};
