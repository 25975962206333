/**
 * @description Checks if the provided object is a record (plain JavaScript object) with string keys and unknown values.
 * @param {unknown} obj - The object to check.
 * @returns {obj is Record<string, unknown>} - A boolean value indicating whether the object is a record.
 * @example
 * ```
 * const obj1 = { name: 'John', age: 30 };
 * const obj2 = [1, 2, 3];
 *
 * console.log(isRecord(obj1)); // true
 * console.log(isRecord(obj2)); // false
 * ```
 */
export const isRecord = (obj: unknown): obj is Record<string, unknown> => {
  return (
    typeof obj === "object" ||
    typeof obj === 'function' ||
    (
      obj instanceof Object &&
      !(obj instanceof Array)
    )
  ) && (
      obj !== null &&
      obj !== undefined
    )
}

/**
 * @description Checks if the provided object has a specific property key.
 * @param {unknown} obj - The object to check for the property key.
 * @param {PropertyKey} prop - The property key to check.
 * @returns {obj is X & Record<Y, unknown>} - A boolean value indicating whether the object has the specified property key.
 * @example
 * ```
 * const person = { name: 'John', age: 30 };
 *
 * console.log(hasKey(person, 'name')); // true
 * console.log(hasKey(person, 'email')); // false
 * ```
 */

export function hasKey<X extends {}, Y extends PropertyKey>(obj: unknown, prop: Y): obj is X & Record<Y, unknown> {
  return obj !== null && obj !== undefined && Object.prototype.hasOwnProperty.call(obj, prop) || (isRecord(obj) && prop in (obj as Record<string, unknown>));
}

/**
 * @description Retrieves the value of a specified key from an object, or returns a default value if the key is not present.
 * @param {unknown} obj - The object to retrieve the value from.
 * @param {string | number} key - The key of the value to retrieve.
 * @param {T} defaultValue - The default value to return if the key is not present in the object.
 * @returns {T} - The value associated with the specified key, or the default value if the key is not present.
 * @example
 * ```
 * const person = { name: 'John', age: 30 };
 *
 * console.log(getValue(person, 'name', 'Unknown')); // 'John'
 * console.log(getValue(person, 'email', 'Unknown')); // 'Unknown'
 * ```
 */
export const getValue = <T = unknown>(obj: unknown, key: string | number, defaultValue: T): T => {
  return isRecord(obj) && hasKey(obj, key) && obj[key] as typeof defaultValue || defaultValue;
}