import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { logger } from "../../util/Logger";
import { slideInAnimation } from "../../util/animations";

const className = "MainTemplateComponent";
declare let ga: Function;

@Component({
  moduleId: module.id,
  animations: [slideInAnimation],
  selector: 'app-main-template',
  templateUrl: 'main-template.component.html',
  styleUrls: ['main-template.component.scss']
})
export class MainTemplateComponent implements OnInit {

  year: string = new Date().getFullYear().toString();

  constructor(
  ) {
  }

  ngOnInit() {
    this.blurNumberInputOnWheel();
  }

  prepareRoute(outlet: RouterOutlet, data: string) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[data];
  }

  blurNumberInputOnWheel() {
    const signature = className + ".blurNumberInput: ";
    document.addEventListener("wheel", function () {
      if (
        document.activeElement
        && document.activeElement["type"]
        && document.activeElement["type"] === "number"
        && document.activeElement["blur"]
      ) {
        logger.silly(signature + "Prevented mWheel in Number Input");
        document.activeElement["blur"]();
      }
    });
  }
}
