import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SampleService } from '../../services/sample.service';

@Component({
  moduleId: module.id,
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {
  public apiURL = environment.endpoint;

  constructor(
    public sampleService: SampleService
  ) { }

  ngOnInit() {
    this.sampleService.getSample(1).subscribe({
      next: data => {
        console.log(data);
      },
      error: err => {
        console.error(err);
      }
    });
  }
}
