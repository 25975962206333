import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { SampleApi } from '../api/sample.api';

@Injectable()
export class SampleService {
  constructor(
    private notifications: NotificationsService,
    private sampleApi: SampleApi
  ) {
  }

  public getSample(id: number): Observable<unknown> {
    return apiCallWrapper(
      this.sampleApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Obtaining new access keys"
      }
    )
  }
}
