import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, skip, switchMap } from "rxjs/operators";
import { logger } from "../util/Logger";
import { environment } from "src/environments/environment";
import { JwtService } from "../services/jwt.service";
import { AuthService } from "../services/auth.service";
import { ErrorMessage } from "../model/api.model";
import { HeaderTokenEnum, IJWTPayload } from "../model/auth.model";

const className = "JwtInterceptor";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private readonly jwtService: JwtService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {
  }

  private tokenRefreshInProgress = false;
  private tokenRefreshComplete$ = new BehaviorSubject<null>(null);

  /**
   * @description Intercepts HTTP requests and performs authorization checks and token refresh if necessary. Attaches the authorization headers to the request object or bypasses the authentication process for certain conditions.
   * @param {HttpRequest<T>} request - The HTTP request object.
   * @param {HttpHandler} next - The next handler in the chain.
   * @returns {Observable<HttpEvent<T>>} - An observable that emits the HTTP event after applying authorization headers or bypassing the authentication process.
   */
  intercept<T = unknown>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const signature = className + `.intercept: Method[${request.method}] to Url[${request.url}] `;

    // No point in evaluating auth if the user is offline
    if (!window.navigator.onLine) {
      logger.silly(signature + `Bypassing due to onLine[${window.navigator.onLine}]`);
      return next.handle(request);
    }

    logger.silly(signature + "Intercepting HTTPRequest");

    /** Do not attach the authentication token unless we are looking at our own api */
    if (request.url.indexOf(environment.endpoint) !== 0) {
      logger.silly(signature + "Found external Endpoint. Authorization not required");
      return next.handle(request);
    }

    if (request.headers.get("tokenType") === HeaderTokenEnum.NoToken) {
      logger.silly(signature + "Found public headers. Authorization not required");
      return next.handle(request);
    }

    logger.silly(signature + "Adding Authorization Data");

    const payload = this.jwtService.currentJwtPayload$.getValue();

    if (!payload) {
      logger.debug(signature + "JWT Payload is blank");
      return this.onAuthorizationFailed("Authorization keys were not found on endpoint expecting authorization");
    }

    if (this.jwtService.isExpired(payload)) {

      if (!this.tokenRefreshInProgress) {
        logger.silly(signature + "Refreshing expired access token");
        this.authService.refreshToken(
          this.jwtService.getJWTString(),
          this.jwtService.getJWTRefreshString()
        ).pipe(
          switchMap(payload => {
            return this.onRefresh(payload, request, next);
          })
        ).subscribe({
          next: () => {
            this.tokenRefreshInProgress = false;
            this.tokenRefreshComplete$.next(null);
          },
          error: err => {
            logger.silly(signature + "Handling refresh error");

            if (err instanceof ErrorMessage) {
              if (err.statusCode === 403) {
                logger.warn(signature + "Unable to obtain new credentials. Redirecting for Authentication");
                this.jwtService.removeJWTData();
                this.router.navigateByUrl('/login');
                err.handled = true;
              }
            }

            this.tokenRefreshInProgress = false;
            this.tokenRefreshComplete$.error(err);
          }
        });

        this.tokenRefreshInProgress = true;
      } else {
        logger.silly(signature + "Awaiting updated access token");
      }

      // Perform the Token refresh
      return this.tokenRefreshComplete$.pipe(
        skip(1),
        switchMap(() => {
          return this.onRefreshComplete(request, next);
        }),
        catchError(err => {
          logger.silly(signature + "Token Refresh Failed");
          return throwError(err);
        })
      );
    }

    // JWT should now be known to exist and be not expired, but double check it
    if (!this.jwtService.verifyJWT(payload)) {
      logger.debug(signature + "JWT is invalid");
      return this.onAuthorizationFailed("Invalid authorization keys were found");
    }

    const modifiedRequest = this.setAuthorizationRequest(request);

    if (modifiedRequest) {
      logger.silly(signature + "Successfully attached Authorization");
      return next.handle(modifiedRequest) as Observable<HttpEvent<T>>;
    } else {
      return this.onAuthorizationFailed("Unable to insert authorization into request");
    }
  }

  /**
   * @description Handles the failure of an authorization operation by logging the error, redirecting the user to the login page, and returning an observable that emits an error.
   * @param {unknown | null} error - The error object or null.
   * @returns {Observable<never>} - An observable that emits an error.
   * @private
   * @example
   * ```
   * const error = new Error("Authorization failed");
   *
   * onAuthorizationFailed(error)
   *   .subscribe({
   *     next: () => {},
   *     error: (error) => {
   *       console.error(error); // Handle error
   *     },
   *     complete: () => {}
   *   });
   * ```
   */
  private readonly onAuthorizationFailed = (error: unknown | null = null): Observable<never> => {
    const signature = className + ".onAuthorizationFailed: ";

    logger.error(error);

    // TODO: Send the user to the auth URL when failing Auth
    this.router.navigate(["/login"]);

    return throwError(error);
  }

  /**
   * @description Handles the completion of a token refresh operation by saving the refreshed JWT payload, checking if the user is authenticated, and returning an observable indicating the success or failure of the operation.
   * @param {IJWTPayload} payload - The refreshed JWT payload object.
   * @param {HttpRequest<T>} request - The original request object.
   * @param {HttpHandler} next - The next handler in the chain.
   * @returns {Observable<boolean>} - An observable that emits a boolean value indicating the success or failure of the token refresh operation.
   * @private
   * @example
   * ```
   * const jwtPayload = {
   *   // Refreshed JWT payload
   * };
   * const httpRequest = new HttpRequest('GET', 'https://api.example.com/data');
   * const httpHandler: HttpHandler = ...;
   *
   * onRefresh(jwtPayload, httpRequest, httpHandler)
   *   .subscribe(success => {
   *     console.log(success); // true if the token refresh was successful, false otherwise
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */

  private readonly onRefresh = <T = unknown>(payload: IJWTPayload, request: HttpRequest<T>, next: HttpHandler): Observable<boolean> => {
    if (!(this.jwtService.saveJWTData(payload)) || !this.authService.isAuthenticated()) {
      this.jwtService.removeJWTData();

      return throwError("Error Saving JWT Payload");
    }

    return of(true);
  }

  /**
   * @description Handles the completion of a token refresh operation by attaching authorization headers to the modified request object and forwarding it to the next handler in the chain.
   * @param {HttpRequest<T>} request - The original request object.
   * @param {HttpHandler} next - The next handler in the chain.
   * @returns {Observable<HttpEvent<T>>} - An observable that emits the HTTP event from the next handler after applying the authorization headers, or an error observable if the authorization insertion fails.
   * @private
   * @example
   * ```
   * const httpRequest = new HttpRequest('GET', 'https://api.example.com/data');
   * const httpHandler: HttpHandler = ...;
   *
   * onRefreshComplete(httpRequest, httpHandler)
   *   .subscribe(event => {
   *     console.log(event); // HTTP event from the next handler with authorization headers
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  private readonly onRefreshComplete = <T = unknown>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> => {
    const signature = className + '.onRefreshComplete: ';
    logger.silly(signature + 'Started');
    const repeatModifiedRequest = this.setAuthorizationRequest(request) as typeof request;

    if (repeatModifiedRequest) {
      return next.handle(repeatModifiedRequest) as Observable<HttpEvent<T>>;
    } else {
      return this.onAuthorizationFailed("Unable to insert authorization into request");
    }
  }

  /**
   * @description Checks if the last known JWT token is valid and attaches the authorization headers to the provided request object.
   * @param {HttpRequest<any>} request - The request object to which the authorization headers will be attached.
   * @returns {HttpRequest<any> | null} - The modified request object with authorization headers, or null if the JWT token is invalid or missing.
   * @private
   * @example
   * ```
   * const httpRequest = new HttpRequest('GET', 'https://api.example.com/data');
   *
   * const authorizedRequest = setAuthorizationRequest(httpRequest);
   * if (authorizedRequest) {
   *   console.log(authorizedRequest.headers); // Authorization headers are attached
   * } else {
   *   console.log('JWT token is invalid or missing');
   * }
   * ```
   */

  private readonly setAuthorizationRequest = <T = unknown>(request: HttpRequest<T>): HttpRequest<T> | null => {
    const validPayload = this.jwtService.currentJwtPayload$.getValue();

    if (validPayload) {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.jwtService.getJWTTypeString()} ${this.jwtService.getJWTString()}`
        }
      });

      return request;
    }

    return null;
  };
}