import {
  AuthApi
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { IAuthPayload, IForgotPayload, IJWTPayload, IResetPayload } from '../model/auth.model';

interface auth {
  email: string,
  password: string
}

@Injectable()
export class AuthService {
  constructor(
    private authApi: AuthApi,
    private jwtService: JwtService,
    public notifications: NotificationsService,
  ) { }

  /**
   * @description Refreshes the authentication token by making a request to the server using the provided JWT strings.
   * @param {string} jwtString - The access token JWT string.
   * @param {string} jwtRefreshString - The refresh token JWT string.
   * @returns {Observable<IJWTPayload>} - An observable that emits the refreshed JWT payload upon successful token refresh.
   * @public
   * @example
   * ```
   * const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Access token JWT string
   * const refreshToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Refresh token JWT string
   *
   * auth.refreshToken(accessToken, refreshToken)
   *   .subscribe(jwtPayload => {
   *     console.log(jwtPayload); // The refreshed JWT payload
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public readonly refreshToken = (jwtString: string, jwtRefreshString: string): Observable<IJWTPayload> => {
    return apiCallWrapper(
      this.authApi.refresh({ accessToken: jwtString, refreshToken: jwtRefreshString }),
      {
        notificationsService: this.notifications,
        action: "Obtaining new access keys"
      }
    )
  }

  /**
   * @description Resets the user's password by making a request to the server using the provided reset payload.
   * @param {IResetPayload} opts - The payload object for the password reset request.
   * @returns {Observable<any>} - An observable that emits the response from the password reset request.
   * @public
   * @example
   * ```
   * const resetPayload = {
   *   email: 'john@example.com',
   *   newPassword: 'newPassword123'
   * };
   *
   * auth.reset(resetPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the password reset request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public reset = (opts: IResetPayload) => {
    return apiCallWrapper(
      this.authApi.reset(opts),
      {
        notificationsService: this.notifications,
        action: "Changing password"
      }
    );
  }

  /**
   * @description Authenticates the user by making a request to the server using the provided authentication payload.
   * @param {IAuthPayload} opts - The payload object for the authentication request.
   * @returns {Observable<any>} - An observable that emits the response from the authentication request.
   * @public
   * @example
   * ```
   * const authPayload = {
   *   username: 'john@example.com',
   *   password: 'password123'
   * };
   *
   * auth.authenticate(authPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the authentication request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */

  public authenticate = (opts: IAuthPayload) => {
    return apiCallWrapper(
      this.authApi.authenticate(opts),
      {
        notificationsService: this.notifications,
        action: "Authenticating"
      }
    )
  }

  /**
   * @description Sends a forgot password request by making a request to the server using the provided forgot payload.
   * @param {IForgotPayload} opts - The payload object for the forgot password request.
   * @returns {Observable<any>} - An observable that emits the response from the forgot password request.
   * @public
   * @example
   * ```
   * const forgotPayload = {
   *   email: 'john@example.com'
   * };
   *
   * auth.forgot(forgotPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the forgot password request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */

  public forgot = (opts: IForgotPayload) => {
    return apiCallWrapper(
      this.authApi.forgot(opts),
      {
        notificationsService: this.notifications,
        action: "Reset Password",
        failTitle: "Reset Failed",
        successTitle: "Reset Complete",
        successMessage: "Instructions were sent to your email address"
      }
    )
  }

  /**
   * @description Checks if the user is authenticated by evaluating the current JWT payload.
   * @returns {boolean} - A boolean value indicating whether the user is authenticated or not.
   * @public
   * @example
   * ```
   * const authenticated = auth.isAuthenticated();
   * console.log(authenticated); // true or false
   * ```
   */
  public isAuthenticated = () => this.jwtService.currentJwtPayload$.getValue() !== null;
}