import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MainTemplateComponent } from "./template/main-template/main-template.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { HomeComponent } from "./view/home/home.component";
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { JwtService } from "./services/jwt.service";
import { StorageService } from "./services/storage.service";
import { AuthService } from "./services/auth.service";
import { SampleService } from "./services/sample.service";
import { SampleApi } from "./api/sample.api";
import { environment } from "src/environments/environment";
import { SimpleNotificationsModule } from "angular2-notifications";
import { AuthApi } from "./api/auth.api";

@NgModule({
  declarations: [
    HomeComponent,
    MainTemplateComponent,
  ],
  imports: [
    BrowserModule,

    AppRoutingModule,
    HttpClientModule,

    /* Alerts - https://www.npmjs.com/package/angular2-notifications */
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [

    AuthApi,
    AuthService,

    JwtService,
    StorageService,

    SampleApi,
    SampleService,

    /** Sentry */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [MainTemplateComponent],
})
export class AppModule { }
